import React from "react"
import SEO from '../components/seo';
import Layout from '../containers/layout/layout'
import ContactFormSection from '../containers/contact/contact-form'
import ContactInfoSection from '../containers/contact/contact-info'

export default () => (
    <Layout headerStyle="darkHeader">
        <SEO 
            title="Маркетинговое агентство ostrovsky-marketing"
            description={
                `Приглашаем в наш уютный офис. Адрес:
                344068 пр. Михаила Нагибина, 40 оф. 58
                Ростов-на-Дону Ростовская обл. 
                +7 (950) 868-44-44
                Igor@ostrovsky-marketing.ru`
            }
        />
        <ContactFormSection/>
        <ContactInfoSection/>
    </Layout>
)
