import React from 'react'
// import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { YMaps, Map, Placemark } from "react-yandex-maps";
import marker from "../../assets/img/icons/map-pin.svg"
 
export const MapContainer = () => {
  // const onMarkerClick = (e) => {
  //   console.log(e)
  // } [47.26769155048544,39.72399441129255] 

  const mapData = {
    center: [47.26769155048544,39.72399441129255],
    zoom: 18,
  };

  const coordinates = [
    [47.26769155048544,39.72399441129255]
  ];

  return (
    // <Map 
    //   google={props.google} 
    //   zoom={14}
    //   initialCenter={{
    //       lat: 23.7808875,
    //       lng: 90.2792371
    //   }}
    // >iconLayout: 'default#image',

    //   <Marker onClick={onMarkerClick}
    //           name={'Current location'} />
    // </Map>
    <YMaps>
      <Map defaultState={mapData} width={"100%"} height={"100%"} >
        {coordinates.map(coordinate => <Placemark key={coordinate} geometry={coordinate} properties={{ iconLayout:'default#image', iconContentLayout: marker }}/>)}
      </Map>
    </YMaps> 
  );
}
 
// export default GoogleApiWrapper({
//   apiKey: ('AIzaSyBmGmeot5jcjdaJTvfCmQPfzeoG_pABeWo')
// })(MapContainer)
export default MapContainer